
import '../../css/custom.css';
import '../../css/style.default.css';
import '../../css/styles.css';

function Footer() {
  return (
            <footer className="main-footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                 <p>Radhe News © 2022</p>
                            </div>
                        </div>
                    </div>
            </footer>
  
        );
}

export default Footer;