import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { x_op_key, x_op_secret } from "../../const";
import Footer from "../Footer";
import Table from "../Table";

const Mergeapi = () => {
  const [api1, setApi1] = useState();
  const [api2, setApi2] = useState();
  const [match, setMatch] = useState();
  const [mergedAPi, setMergedApi] = useState();
  const [crickAPIs, setcrickAPIs] = useState();
  const [socketAPIs, setSocketAPIs] = useState();
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(api1);
    console.log(api2);
    if (api1 === undefined || api2 === undefined) {
      alert("Enter non empty values!");
      return;
    }
    var data = {
      key: api1?.trim() + "^" + api2?.trim(),
      match: match,
    };
    fetch("https://rnews-api.devserapp.com/mergeapi", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "Application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          alert("submitted");
          fetchKeys();
          setMergedApi(api1 + "^" + api2);
        }else if(res.status===400){
          alert('Duplicate Entry!')
        }
        else{
          alert('something went wrong ! please try again.')
          return
        }
      });
  };
  const [body, setBody] = useState();
  useEffect(() => {
    fetchKeys();
    fetchCrickApis();
    fetchSocketApis();
  }, []);
  const fetchCrickApis = () => {
    fetch("https://rnews-api.devserapp.com/getmatches")
      .then((res) => res.json())
      .then((res) => setcrickAPIs(res.matches))
      .catch((err) => console.log(err));
  };
  const fetchSocketApis = () => {
    fetch("https://api.scoreshub.live/marketprovider/Markets", {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Headers":
          "Access-Control-Request-Headers, X-Requested-With,x-op-key,Accept,Authorization",
        "Access-Control-Allow-Origin": "*",
        "x-op-secret": x_op_secret,
        "x-op-key": x_op_key,
      },
    })
      .then((res) => res.json())
      .then((res) =>
        setSocketAPIs(res.filter((item) => item.eventType.name === "Cricket"))
      )
      .catch((err) => console.log(err));
    // console.log(socketAPIs)
  };
  const fetchKeys = () => {
    fetch("https://rnews-api.devserapp.com/mergedapi")
      .then((res) => res.json())
      .then((res) => {setBody(res)})
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Merge API</h2>
          </div>
        </header>
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      id="profileFrm"
                      name="profileFrm"
                      className="form-horizontal"
                      onSubmit={submitHandler}
                    >
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                      />{" "}
                      {crickAPIs?.map((i) => (
                        <div style={{ padding: 10 }}>
                          <span>{i.key} : </span> <span> {i.name}</span>{" "}
                          <span> Time : </span>{" "}
                          <span>{moment.unix(i?.start_at).format("lll")}</span>
                        </div>
                      ))}
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          API-1 (enter key from above data!)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={api1 ? api1 : ""}
                            onChange={(e) => {
                              setApi1(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="line" />
                      {socketAPIs?.map((i) => (
                        <div style={{ padding: 10 }}>
                          <span>{i.event.id} : </span>{" "}
                          <span> {i.event.name}</span> <span> Time : </span>{" "}
                          <span>{Date(i.event.openDate)}</span>
                        </div>
                      ))}
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          API-2 (enter key from above data!)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={api2 ? api2 : ""}
                            onChange={(e) => {
                              setApi2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Match
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={match ? match : ""}
                            onChange={(e) => {
                              setMatch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Mergerd-API
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            value={mergedAPi ? mergedAPi : ""}
                          />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Match
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            value={match ? match : ""}
                          />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <Link
                            to="/home/mergeapi"
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              setApi1("");
                              setApi2("");
                              setMergedApi("");
                            }}
                          >
                            Cancel
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Merge
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {true && (
                    <Table
                      heading={["Key(merged)", "Match"]}
                      body={body?.sort((x, y) => {
                        if (
                          x.country_code?.toLowerCase() <
                          y.country_code?.toLowerCase()
                        ) {
                          return -1;
                        }
                        return 1;
                      })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Mergeapi;
