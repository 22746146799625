import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../assets/img/logo.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";

function Header() {
  const [settings, setSettings] = useState(false);
  const [menubtn, setMenubtn] = useState(false);
  const location = useLocation();
  return (
    <header className="header">
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-holder d-flex align-items-center justify-content-between">
            {/* Navbar Header*/}
            <div className="navbar-header">
              {/* Navbar Brand */}
              <Link
                to="/home"
                className="navbar-brand d-none d-sm-inline-block"
              >
                <div className="brand-text d-none d-lg-inline-block">
                  <img
                    src={require("../../assets/img/logo.png")}
                    className="logo-img"
                  />
                  <strong>Dashboard</strong>
                </div>
                <div className="brand-text d-none d-sm-inline-block d-lg-none">
                  <strong>VD</strong>
                </div>
              </Link>
              {/* Toggle Button*/}
              <Link
                to={{pathname:`${location.pathname}` ,state:{ menubtn: !menubtn }}}
              
                className={menubtn ? "menu-btn active" : "menu-btn"}
                onClick={() => {
                  setMenubtn(!menubtn);
                }}
              >
                <span />
                <span />
                <span />
              </Link>
            </div>
            {/* Navbar Menu */}
            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              {/* Settings dropdown    */}
              <li className="nav-item dropdown">
                <Link
                  to={`${location.pathname}`}
                  id="settings"
                  rel="nofollow"
                  data-target="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link language dropdown-toggle"
                  onClick={() => {
                    setSettings(!settings);
                  }}
                >
                  <i className="fa fa-gear" />
                  <span className="d-none d-sm-inline-block" />
                  Settings
                </Link>
                {settings && (
                  <ul aria-labelledby="settings" className="dropdown-menu show">
                    <li>
                      <Link
                      to='/home/manageprofile'
                        rel="nofollow"
                        href="admin-profile.html"
                        className="dropdown-item"
                      >
                        {" "}
                        <i className="fa fa-user" /> Manage Profile
                      </Link>
                    </li>
                    {/* <li>
                      <Link to='/home/changepassword'
                        rel="nofollow"
                        href="change-password.html"
                        className="dropdown-item"
                      >
                        {" "}
                        <i className="fa fa-key" /> Change Password
                      </Link>
                    </li> */}
                  </ul>
                )}
              </li>
              {/* Logout    */}
              <li className="nav-item">
                <Link to="/login" className="nav-link logout" onClick={()=>{
                  window.localStorage.setItem('token','null')
                }}>
                  <span className="d-none d-sm-inline">Logout</span>
                  <i className="fa fa-sign-out" />
                </Link>
              </li>
              <form
                id="admin-logout-form"
                action="login.html"
                method="POST"
                style={{ display: "none" }}
              >
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                />{" "}
              </form>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
