import Avtar from "../../assets/img/avtar.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";

function EditPlayer() {
  const history=useHistory();
  const location=useLocation()
  // console.log(location)
  if(location.state){
    var {player}=location.state
  }
  const [title, setTitle] = useState(player?player?.player_name:'');
  const [code, setCode] = useState(player?player?.player_code:'');
  const [logo, setLogo] = useState(null);
  const [url, setUrl] = useState();
  const[countryCode,setCountryCode]=useState(player?player?.country_code:'');
  const[teamName,setTeamName]=useState(player?player?.team_name:'');
  const{action}=useParams()

    
    
  const submitHandler = (e) => {
    
    
    e.preventDefault();
    if(title?.length===0||code?.length===0||countryCode.length===0){
      
      alert("Enter valid Data!")
      return
  }
    // if(!logo){
   
    //   alert("Empty Fields not allowed!")
    //   return
    // }
    var temp = {
        id: code,
        player_name: title,
        player_code: code,
        team_name:teamName,
        country_code:countryCode,
        playerImage:logo 
      };
      var data=new FormData()

      for(const key in temp){
          data.append(key,temp[key])
      }
  

    fetch("https://rnews-api.devserapp.com/addplayers",{method:'POST',body:data}).then(res=>res.json()).then(res=>{console.log(res);history.push('/home/manageplayer')}).catch(err=>console.log(err))
    
  };
  var reader = new FileReader();
  console.log('logo1',logo)
  if(logo){
      reader.readAsDataURL(logo)
      reader.onload = e => {
        setUrl(e.target.result);
    }
  }
  
  return (
    <div className="content-inner" style={{ paddingBottom: "59.1875px" }}>
      {/* Page Header*/}
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Update Player Detals</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <form
                    id="manage-driver"
                    name="manage_driver"
                    onSubmit={submitHandler}
                  >
                    <input
                      type="hidden"
                      name="_token"
                      
                    />{" "}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Player Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          defaultValue={player?.player_name}
                          id="name"
                          type="text"
                          name="Team Title"
                          className="form-control"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Player Code
                      </label>
                      <div className="col-sm-9">
                        <input
                        defaultValue={player?.player_code}
                          id="code"
                          type="number"
                          name="code"
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Team Name
                      </label>
                      <div className="col-sm-9">
                        <input
                        defaultValue={player?.team_name}
                          id="code"
                          type="text"
                          name="code"
                          onChange={(e) => {
                            setTeamName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Team Code
                      </label>
                      <div className="col-sm-9">
                        <input
                        defaultValue={player?.country_code}
                          id="code"
                          type="number"
                          name="code"
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Player Photo
                      </label>
                      <div className="col-sm-9">
                        <input
                            id="image"
                          type="file"
                          //   name="image"
                          className="form-control"
                          onChange={(e) => {
                            setLogo(e.target.files[0]);
                          }}
                        />
                        {logo && <img
                          src={url}
                          className="img-fluid rounded-circle"
                          width="100px"
                          height="100px"
                        />}
                        {!logo && player && <img src={`https://rnews-api.devserapp.com/getplayer/${player.country_code+'_'+player.player_code}`} className="img-fluid rounded-circle" width={100}/>

                        }
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <Link to='/home/manageplayer'
                          type="button"
                          className="btn btn-secondary"
                         
                        >
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditPlayer;
