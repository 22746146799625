import '../../css/custom.css';
import '../../css/style.default.css';
import '../../css/styles.css';


function ChangePassword (){
    return(
        <div className="content-inner">
            {/* Page Header*/}
            <header className="page-header">
            <div className="container-fluid">
                <h2 className="no-margin-bottom">Change Password</h2>
            </div>
            </header>
            <section className="forms">
            <div className="container-fluid">
                <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                    <div className="card">
                    <div className="card-body">
                        <form id="changeFrm" name="changeFrm" action="#" method="POST" className="form-horizontal" encType="multipart/form-data" noValidate="novalidate">
                        <input type="hidden" name="_token" defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj" />                            <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Current Password</label>
                            <div className="col-sm-9">
                            <input id="current_password" name="current_password" type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="line" />
                        <div className="form-group row">
                            <label className="col-sm-3 form-control-label">New Password</label>
                            <div className="col-sm-9">
                            <input id="password" name="password" type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="line" />
                        <div className="form-group row">
                            <label htmlFor="fileInput" className="col-sm-3 form-control-label">Confirm Password</label>
                            <div className="col-sm-9">
                            <input id="password_confirmation" name="password_confirmation" type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="line"> </div>
                        <div className="form-group row">
                            <div className="col-sm-4 offset-sm-3">
                            <button type="button" className="btn btn-secondary" onClick="location.href='index.html'">Cancel</button>
                            <button type="submit" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
      
    );   
}

export default ChangePassword;