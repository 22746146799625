import { useState } from "react";
import { Link, NavLink, Redirect, Route, Switch } from "react-router-dom";
import "../../css/styles.css";
import "../../css/custom.css";
// // import  '../../css/font-awesome.min.css'
// import  '../../css/fontastic.css'
import "../../css/style.default.css";
import "../../css/styles.css";
import ManageTeam from "../ManageTeam/index";
import ManagePlayer from "../ManagePlayer/index";
import Advertise from "../AdvertiseWeb/index";
import ManageProfile from "../ManageProfile/index";
import Footer from "../Footer/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Dashboard = () => {
  document.title = "Radhe News | Admin";
  const history=useHistory();

  // if(true){
    
  // }
  const [menubtn, setMenubtn] = useState(false);
  const [settings, setSettings] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const [team, setTeam] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [player, setPlayer] = useState(false);
  const [ad, setAd] = useState(false);

  return (
    <>
      <div className="content-inner">
              {/* Page Header*/}
              <header className="page-header">
                <div className="container-fluid">
                  <h2 className="no-margin-bottom">Dashboard</h2>
                </div>
              </header>
              <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                  <div className="row bg-white has-shadow">
                    <div className="col-xl-3 col-sm-6">
                      <NavLink exact to="/home/mergeapi"
                      onClick={() => {
                        setTeam(true);
                        setDashboard(false);
                        setAd(false);
                        setAdmin(false);
                        setPlayer(false);
                        
                      }}>
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-violet">
                            <i className="fa fa-users" />
                          </div>
                          <div className="title">
                            <span>Merge Api</span>
                            {/* <div class="number"><strong>4</strong></div> */}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                      {/* <NavLink to="/home/manageplayer"
                     
                        >
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-violet">
                            <i className="fa fa-users" />
                          </div>
                          <div className="title">
                            <span>Player</span>
                          </div>
                        </div>
                      </NavLink> */}
                    </div>
                    <div className="col-xl-3 col-sm-6">
                    <NavLink to="/home/ads"
                      
                        >
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-violet">
                            <i className="fa fa-users" />
                          </div>
                          <div className="title">
                            <span>Advertise</span>
                            {/* <div class="number"><strong>4</strong></div> */}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
               
              </section>
              <Footer/>

             
            
            </div>
    </>
  );
};
export default Dashboard;
