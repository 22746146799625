import React, { Suspense, useEffect, useRef, useState } from "react";
import "./css/styles.css";
import "./css/custom.css";
import "./fontawesome-free-6.0.0-beta3-web/css/all.min.css";
import "./css/fontastic.css";
import "./css/style.default.css";
import "./css/styles.css";
import HomePage from "./pages/HomePage/homePage.js";
function App() {
  // console.log(localStorage.getItem('isLoggedin')
  // localStorage.setItem('token',null)
  document.title='Rnews Admin Panel'

  return (
    <>
      <HomePage />
    </>
  );
}

export default App;

//"https://firebasestorage.googleapis.com/images/_uploads_hashnode_Bugatti.jpg"
