import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import '../../css/custom.css';
import '../../css/style.default.css';
import '../../css/styles.css';


function Reset (){
    const submitHandler=()=>{
        alert("Sent!!");
        
    }
    return (
        <div className="page login-page">
            <div className="container d-flex align-items-center">
            <div className="form-holder has-shadow">
                <div className="row">
                {/* Logo & Information Panel*/}
                <div className="col-lg-6">
                    <div className="info d-flex align-items-center">
                    <div className="content text-center">
                        <div className="logo">
                        <div className="logo-inner">
                            <img src={Logo} className="logo-img" />
                        </div>
                        <h1>Forgot Password</h1>
                        </div>
                        <p>Reset your password here</p>
                    </div>
                    </div>
                </div>
                {/* Form Panel    */}
                <div className="col-lg-6 bg-white">
                    <div className="form d-flex align-items-center">
                    <div className="content">
                        <form id="passwordFrm" name="passwordFrm" onSubmit={submitHandler}>
                        <input type="hidden" name="_token" defaultValue="FPCwAI3PXUKMn2rVDWTcRwGZ9872Ikll3Vj6tOJU" />                <div className="form-group">
                            {/* <input id="email" type="email" className="input-material " name="email" defaultValue autoComplete="email" data-msg="Please enter email address" autofocus /> */}
                            <input id="email" type="email" className="input-material" name="email" />   
                            <label htmlFor="email" className="label-material">E-Mail Address</label>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                            Send Password Reset Link
                            </button>
                            <Link to='/login' className="btn btn-primary" onClick="location.href='login.html'">
                            Back To Login
                            </Link>
                        </div>
                        {/* This should be submit button but I replaced it with <a> for demo purposes*/}
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      
    );
}

export default Reset;