import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avtar from "../../assets/img/logo.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";
import Footer from "../Footer/index";

function AdvertiseMobile() {
  const [curAds,setCurAds]=useState();  
  const [curHBB,setCurHBB]=useState();  
  const [curIS,setCurIS]=useState();  
  const [adsImg, setAdsImg] = useState();
  const [HBBImg, setHBBImg] = useState();
  const [ISImg, setISImg] = useState();
  const [Adsurl, setAdsurl] = useState();
  const [HBBurl, setHBBurl] = useState();
  const [ISurl, setISurl] = useState();
  const [adsInput, setAdsInput] = useState(false);
  const [HBBInput, setHBBInput] = useState(false);
  const [ISInput, setISInput] = useState(false);
  const [adsLinkInput, setAdsLinkInput] = useState();
  const [HBBLinkInput, setHBBLinkInput] = useState();
  const [ISLinkInput, setISLinkInput] = useState();
  var reader = new FileReader();
  useEffect(() => {
    fetch("https://rnews-api.devserapp.com/getadsobj/mobile")
      .then((res) => res.json())
      .then((res) => {
        setCurAds(res[0].link)
        setCurHBB(res[1].link)
        setCurIS(res[2].link)
      });
  }, []);
  
  if (adsImg && !adsInput) {
    reader.readAsDataURL(adsImg);

    reader.onload = (e) => {
      setAdsurl(e.target.result);
      setAdsInput(true);
    };
  }

  if (HBBImg && !HBBInput) {
    reader.readAsDataURL(HBBImg);
    reader.onload = (e) => {
      setHBBurl(e.target.result);
      setHBBInput(true);
    };
  }
  if (ISImg && !ISInput) {
    reader.readAsDataURL(ISImg);
    reader.onload = (e) => {
      setISurl(e.target.result);
      setISInput(true);
    };
  }
  const AdsSubmitHandler = (e) => {
    e.preventDefault();
    var LinkData=adsLinkInput
    if(adsLinkInput?.trim()===''||adsLinkInput===undefined){
      if(curAds){
        LinkData=curAds
      }
      else{
        alert("Enter DATA!")
        return
      }
    }
    const ads_obj = new FormData();
    var data = {
      id: "Ads",
      type: "Ads",
      type: "Ads",
      adsImage:adsImg,
      link: LinkData,
    };
    for (const key in data) {
      ads_obj.append(key, data[key]);
    }

    fetch("https://rnews-api.devserapp.com/addAds/mobile", { method: "POST", body: ads_obj })
      .then((res) => res.json())
      .then((res) => {console.log(res);}).catch(err=>console.log(err));
  };
  const HomeBottomBaannerSubmitHandler = (e) => {
    e.preventDefault();
    var LinkData=HBBLinkInput
    if(HBBLinkInput?.trim()===''||HBBLinkInput===undefined){
      if(curHBB){
        LinkData=curHBB
      }
      else{
        alert("Enter DATA!")
        return
      }
    }
    const ads_obj = new FormData();
    var data = {
      id: "HomeBottomBaanner",
      type: "HomeBottomBaanner",
      adsImage:HBBImg,
      link: LinkData,
    };
    for (const key in data) {
      ads_obj.append(key, data[key]);
    }

    fetch("https://rnews-api.devserapp.com/addAds/mobile", { method: "POST", body: ads_obj })
      .then((res) => res.json())
      .then((res) => {console.log(res);}).catch(err=>console.log(err));
  };
  const InnerScreenSubmitHandler = (e) => {
    e.preventDefault();
    var LinkData=ISLinkInput
    if(ISLinkInput?.trim()===''||ISLinkInput===undefined){
      if(curIS){
        LinkData=curIS
      }
      else{
        alert("Enter DATA!")
        return
      }
    }
    const ads_obj = new FormData();
    var data = {
      id: "InnerScreen",
      type: "InnerScreen",
      adsImage:ISImg,
      link: LinkData,
    };
    for (const key in data) {
      ads_obj.append(key, data[key]);
    }

    fetch("https://rnews-api.devserapp.com/addAds/mobile", { method: "POST", body: ads_obj })
      .then((res) => res.json())
      .then((res) => {console.log(res);}).catch(err=>console.log(err));
  };
  return (
    <div className="content-inner">
      {/* Page Header*/}
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Manage Advertise for Mobile</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid advertise-btn ">
          <div className="tabs">
            <ul>
              <li className="active">
                <Link to="/home/ads/web">Web</Link>
              </li>
              <li>
                <Link to="/home/ads/mobile">Mobile</Link>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <a href="advertise.html"  class="btn btn-primary mb-4 admin-top">Add New</a> */}
              <div className="card">
                <h4>Ads </h4>
                <div className="card-body">
                  <form
                    id="manage-driver"
                    name="manage_driver"
                    className="form-horizontal"
                    onSubmit={AdsSubmitHandler}
                  >
                    {/* <input
                      type="hidden"
                      name="_token"
                      defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                    />{" "} */}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Advertise Link
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="name"
                          type="text"
                          name="Team Title"
                          defaultValue={curAds}
                          onChange={(e) => {
                            setAdsLinkInput(e.target.value);
                          }}
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {/* <div class="line"></div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Team Code</label>
                                        <div class="col-sm-9">
                                            <input id="code" type="number" name="code" value="" class="form-control" autocomplete="off">
                                        </div>
                                    </div> */}
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Advertise Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="image"
                          type="file"
                          name="image"
                         
                          className="form-control"
                          onChange={(e) => {
                            setAdsInput(false);
                            setAdsImg(e.target.files[0]);
                          }}
                        />
                        {adsImg && (
                          <img
                            src={Adsurl}
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                        {!adsImg && (
                          <img
                            src="https://rnews-api.devserapp.com/getads/Adsmobile"
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <Link
                          to="/home/ads"
                          type="button"
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <a href="advertise.html"  class="btn btn-primary mb-4 admin-top">Add New</a> */}
              <div className="card">
                <h4>Home Bottom Banner </h4>
                <div className="card-body">
                  <form
                    id="manage-driver"
                    name="manage_driver"
                    onSubmit={HomeBottomBaannerSubmitHandler}
                    className="form-horizontal"
                    encType="multipart/form-data"
                    noValidate="novalidate"
                  >
                    {/* <input
                      type="hidden"
                      name="_token"
                      defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                    />{" "} */}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Home Bottom Banner Link
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="name"
                          type="text"
                          name="Team Title"
                          defaultValue={curHBB}
                          className="form-control"
                          autoComplete="off"
                          onChange={(e)=>{setHBBLinkInput(e.target.value)}}
                        />
                      </div>
                    </div>
                    {/* <div class="line"></div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Team Code</label>
                                        <div class="col-sm-9">
                                            <input id="code" type="number" name="code" value="" class="form-control" autocomplete="off">
                                        </div>
                                    </div> */}
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Home Bottom Banner Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="image"
                          type="file"
                          name="image"
                          className="form-control"
                          onChange={(e) => {
                            setHBBInput(false);
                            setHBBImg(e.target.files[0]);
                          }}
                        />
                        {HBBImg && (
                          <img
                            src={HBBurl}
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                        {!HBBImg && (
                          <img
                            src="https://rnews-api.devserapp.com/getads/HomeBottomBaannermobile"
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick="location.href='index.html'"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <a href="advertise.html"  class="btn btn-primary mb-4 admin-top">Add New</a> */}
              <div className="card">
                <h4>Inner Screen</h4>
                <div className="card-body">
                  <form
                    id="manage-driver"
                    name="manage_driver"
                    onSubmit={InnerScreenSubmitHandler}
                    className="form-horizontal"
                    encType="multipart/form-data"
                    noValidate="novalidate"
                  >
                    {/* <input
                      type="hidden"
                      name="_token"
                      defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                    />{" "} */}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Inner Screen Link
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="name"
                          type="text"
                          name="Team Title"
                          defaultValue={curIS}
                          className="form-control"
                          autoComplete="off"
                          onChange={(e)=>{
                            setISLinkInput(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* <div class="line"></div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Team Code</label>
                                        <div class="col-sm-9">
                                            <input id="code" type="number" name="code" value="" class="form-control" autocomplete="off">
                                        </div>
                                    </div> */}
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Inner Screen Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="image"
                          type="file"
                          name="image"
                          className="form-control"
                          onChange={(e) => {
                            setISInput(false);
                            setISImg(e.target.files[0]);
                          }}
                        />
                        {ISImg && (
                          <img
                            src={ISurl}
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                        {!ISImg && (
                          <img
                            src="https://rnews-api.devserapp.com/getads/InnerScreenmobile"
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick="location.href='index.html'"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  );
}

export default AdvertiseMobile;
