import { useState, useEffect, useCallback } from "react";
import Avtar from "../../assets/img/avtar.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";
import Footer from "../Footer/index";
import Table from "../Table/index";
import EditTeam from "../EditTeam/index";
import { Link } from "react-router-dom";

function ManagePlayer() {
  const [isLoding, setIsLoading] = useState();
  const [listVal, setListVal] = useState();
  
  const [body_2, setBody_2] = useState([]);
  const [body, setBody] = useState([]);
  var heading = [
    "Player Photo",
    "Player Name",
    "Player Code",
    "Team Name",
    "Country Code",
    "Action",
  ];
  useEffect(() => {
    setIsLoading(true);
    fetch("https://rnews-api.devserapp.com/getteams")
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);

        setPlayers(res);
      })
      .catch((err) => console.log(err));
      return()=>{}
  }, []);
  const setPlayers = (res) => {
    var temp = [];
    for (var key in res) {
      for (var s_key in res[key].players) {
        temp.push(res[key].players[s_key]);
      }
    }

    setBody(temp);
    setBody_2(temp);
  };

  const compare = (x, y) => {
    //   console.log(x)
    //   console.log(y)
    return x.includes(y);
  };
  return (
    <div className="content-inner">
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Manage Player</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <Link
                to="/home/manageplayer/Add"
                className="btn btn-primary mb-4 admin-top"
              >
                Add New
              </Link>
              {
                <div className="card">
                  <div className="card-body">
                    <div
                      id="data-table_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div className="dataTables_length" id="data-table_length">
                        <label>
                          Show{" "}
                          <select
                            name="data-table_length"
                            aria-controls="data-table"
                            className
                            onChange={(e) => {
                              console.log("changed");
                              setListVal(e.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div id="data-table_filter" className="dataTables_filter">
                        <label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            aria-controls="data-table"
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                // console.log(e.target.value.length)
                                const filtered = body_2?.filter((arr) => {
                                  return (
                                    compare(arr.id, e.target.value) ||
                                    compare(arr.player_name, e.target.value) ||
                                    compare(arr.team_name, e.target.value)
                                  );
                                });
                                setBody(filtered);
                              } else {
                                setBody(body_2);
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div
                        id="data-table_processing"
                        className="dataTables_processing"
                        style={{ display: "none" }}
                      >
                        <div className="loader">
                          <span className="loader-image">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                          </span>
                        </div>
                      </div>

                      {!isLoding && (
                        <Table
                          heading={heading}
                          body={body.slice(0, listVal).sort((x, y) => {
                            if (
                              x.player_name.toLowerCase()+x.player_code <
                              y.player_name.toLowerCase()+y.player_code
                            ) {
                              return -1;
                            }
                            return 1;
                          })}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* {addNew && <><EditTeam onCancel={()=>{setAddNew(false);console.log("onCanel")}}/> 
                
                </>} */}
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default ManagePlayer;
