import { useState } from "react";

const useInput=(validateValue)=>{
    const[enteredValue,setEnteredValue]=useState('');
    const[isTouched,setIsTouched]=useState(false);

    const isValid= validateValue(enteredValue) 
    
    const valueChangeHandler=event=>{
        setEnteredValue(event.target.value)
      
      }
      const InputBlurHandler=(event)=>{
        setIsTouched(true)
       
      }
      const reset =()=>{
        setEnteredValue('')
        setIsTouched(false)
      }
      const hasError= !isValid 
      console.log(enteredValue)
      console.log('error',hasError)
      console.log('VALID',isValid)
      return {
          value: enteredValue,isValid : isValid, hasError : hasError, InputBlurHandler,valueChangeHandler , reset

      }
}
export default useInput;