import { Link } from "react-router-dom";
import Avtar from "../../assets/img/avtar.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";
import Footer from "../Footer/index";

function ManageProfile() {
  return (
    <div className="content-inner">
      {/* Page Header*/}
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Manage Profile</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            {/* Form Elements */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <form
                    id="profileFrm"
                    name="profileFrm"
                    action="#"
                    method="POST"
                    className="form-horizontal"
                    encType="multipart/form-data"
                    noValidate="novalidate"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                    />{" "}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          defaultValue="Super Admin"
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          defaultValue="admin@rnews.com"
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label
                        htmlFor="fileInput"
                        className="col-sm-3 form-control-label"
                      >
                        Photo
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="image"
                          name="image"
                          type="file"
                          className="form-control-file"
                        />
                        <img
                          src={Avtar}
                          className="img-fluid rounded-circle"
                          width="100px"
                          height="100px"
                        />
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <Link to="/home"
                          type="button"
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default ManageProfile;
