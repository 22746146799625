
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Avtar from '../../assets/img/avtar.png';
import '../../css/custom.css';
import '../../css/style.default.css';
import '../../css/styles.css';


function ViewTeam () {
  const {team}=useLocation().state
  console.log(team)
  return (
        <div className="content-inner" style={{paddingBottom: '59.1875px'}}>
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">View Team Details</h2>
          </div>
        </header>
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-2 form-control-label">Team Name</label>
                      <div className="col-sm-4">
                        <input id="name" defaultValue={team?.team_name} className="form-control" disabled />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-2 form-control-label">Team Code</label>
                      <div className="col-sm-4">
                        <input id="code" defaultValue={team?.country_code} className="form-control" disabled />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-2 form-control-label">Team Logo</label>
                      <div className="col-sm-4">
                        <img src={`https://rnews-api.devserapp.com/${team?.country_code}`} width={100} />
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4">
                        <Link to='/home/manageteam'className="btn btn-secondary">Back</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      
    );
}

export default ViewTeam;