import TableRow from "./TableRow";

const Table = (props) => {
  
  
  return (
    <table style={{ width: 500 }}>
      <thead>
        <tr>
          {props?.heading?.map((head) => (
            <th>{head}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.body?.map((row) => (
          <TableRow row={row} />
        ))}
      </tbody>
    </table>
  );
};
export default Table;
