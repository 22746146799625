import { useEffect, useState } from "react";
import Logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import useInput from "../../Hooks/input-hook";

const Login = () => {
  const history = useHistory();
  console.log(history);
  // useEffect(()=>{
  //   localStorage.setItem('token','null')
  // },[])

  // history.replace('/login')
  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (window.localStorage.getItem("token") !== "admin@rnews.com") {
        history.push("/login");
      }
    });
  }, []);

  const [menubtn, setMenubtn] = useState(false);
  const [settings, setSettings] = useState(false);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    InputBlurHandler: emailInputBlurHandler,
    valueChangeHandler: emailChangeHandler,
    reset: resetEmailInput,
  } = useInput((value) => value.trim().includes("@"));
  const {
    value: enteredPassword,
    hasError: passwordInputHasError,
    InputBlurHandler: passwordInputBlurHandler,
    valueChangeHandler: passwordChangeHandler,
    reset: resetPasswordInput,
  } = useInput((value) => value.trim().length > 8);
  let formIsValid = false;
  if (!emailInputHasError && !passwordInputHasError) {
    formIsValid = true;
  }
  const submitHandler = (e) => {
    e.preventDefault();
    if (enteredPassword === "Book@9393" && enteredEmail === "admin@rnews.com") {
      window.localStorage.setItem("token", "admin@rnews.com");
      setTimeout(() => {
        history.push("/home");
      }, 200);

      console.log("Changed");
      if (!formIsValid) {
        alert("Enter Valid Credentials!");
        resetEmailInput();
        resetPasswordInput();
        history.push({ pathname: "/home", state: { loggedin: false } });
        return;
      }

      resetEmailInput();
      resetPasswordInput();
      return;
    }
    alert("Email or Password is Incorrect");
  };
  return (
    <>
      <div>
        {/* Side Navbar */}

        <div>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          {/* CSRF Token */}
          <meta
            name="csrf-token"
            content="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
          />
          <title>Radhe News | Admin</title>
          <meta name="description" content />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="robots" content="all,follow" />
          {/* Bootstrap CSS*/}
          <link rel="stylesheet" href="css/bootstrap.min.css" />
          {/* Font Awesome CSS*/}
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          {/* Fontastic Custom icon font*/}
          <link rel="stylesheet" href="css/fontastic.css" />
          {/* Google fonts - Poppins */}
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Poppins:300,400,700"
          />
          {/* theme stylesheet*/}
          <link
            rel="stylesheet"
            href="css/style.default.css"
            id="theme-stylesheet"
          />
          {/* Favicon*/}
          <link rel="shortcut icon" href="assets/img/favicon.ico" />
          <div className="page login-page">
            <div className="container d-flex align-items-center">
              <div className="form-holder has-shadow">
                <div className="row">
                  {/* Logo & Information Panel*/}
                  <div className="col-lg-6">
                    <div className="info d-flex align-items-center">
                      <div className="content text-center">
                        <div className="logo">
                          <div className="logo-inner">
                            <img src={Logo} className="logo-img" />
                          </div>
                          <h1>Admin Panel</h1>
                        </div>
                        <p>Manage Your Team</p>
                      </div>
                    </div>
                  </div>
                  {/* Form Panel    */}
                  <div className="col-lg-6 bg-white">
                    <div className="form d-flex align-items-center">
                      <div className="content">
                        <form
                          id="loginFrm"
                          name="loginFrm"
                          onSubmit={submitHandler}
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="FPCwAI3PXUKMn2rVDWTcRwGZ9872Ikll3Vj6tOJU"
                          />{" "}
                          <div className="form-group">
                            <input
                              id="email"
                              type="email"
                              className="input-material "
                              name="email"
                              value={enteredEmail}
                              onChange={emailChangeHandler}
                            />
                            <label
                              htmlFor="email"
                              className={
                                !enteredEmail
                                  ? "label-material"
                                  : "label-material active"
                              }
                              autoComplete="true"
                            >
                              E-Mail Address
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              id="password"
                              type="password"
                              className="input-material "
                              name="password"
                              autoComplete="current-password"
                              data-msg="Please enter your password"
                              value={enteredPassword}
                              onChange={passwordChangeHandler}
                            />
                            <label
                              htmlFor="password"
                              className={
                                !enteredPassword
                                  ? "label-material"
                                  : "label-material active"
                              }
                            >
                              Password
                            </label>
                          </div>
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Login
                            </button>
                          </div>
                          {/* This should be submit button but I replaced it with <a> for demo purposes*/}
                        </form>
                        {/* <Link to="/resetpassword" className="forgot-pass">
                          Forgot Your Password?
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyrights text-center">
              <p>
                {" "}
                <a href="#" target="_blank">
                  Radhe News
                </a>
              </p>
            </div>
          </div>
          {/* JavaScript files*/}
        </div>
      </div>
    </>
  );
};

export default Login;
