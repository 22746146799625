import Avtar from "../../assets/img/avtar.png";
import "../../css/custom.css";
import "../../css/style.default.css";
import "../../css/styles.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

function EditTeam() {
  const location = useLocation();
  // console.log(location)
  if (location.state) {
    var { team } = location.state;
  }
  const [title, setTitle] = useState(team?team?.team_name:'');
  const [code, setCode] = useState(team?team?.country_code:'');
  const [logo, setLogo] = useState(null);
  const [url, setUrl] = useState();
  const history = useHistory();

 
  const submitHandler = (e) => {
    e.preventDefault()
    if (title?.length === 0 || code?.length === 0 ) {
     
      alert("Enter valid Data!");
      return;
    }
    var teamData = new FormData();
    var temp = {
      id: code.toString(),
      team_name: title,
      country_code: code,
      teamFlag: logo,
    };

    for (var key in temp) {
      teamData.append(key, temp[key]);
    }
    fetch("https://rnews-api.devserapp.com/addteam", { method: "POST", body: teamData })
      .then((res) => res.json())
      .then((res) => {console.log(res);history.push("/home/manageteam")})
      .catch((err) => console.log(err));
  };
  var reader = new FileReader();
  //   console.log("logo1", logo);
  if (logo !== null||undefined) {
    reader.readAsDataURL(logo);
  }
  reader.onload = (e) => {
    setUrl(e.target.result);
    // console.log(url);
  };

  return (
    <div className="content-inner" style={{ paddingBottom: "59.1875px" }}>
      {/* Page Header*/}
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Update Team Details</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <form
                    id="manage-driver"
                    name="manage_driver"
                    onSubmit={submitHandler}
                  >
                    <input type="hidden" name="_token" />{" "}
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Team Title
                      </label>
                      <div className="col-sm-9">
                        <input
                          defaultValue={team?.team_name}
                          id="name"
                          type="text"
                          name="Team Title"
                          className="form-control"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Team Code
                      </label>
                      <div className="col-sm-9">
                        <input
                          defaultValue={team?.country_code}
                          id="code"
                          type="number"
                          name="code"
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="line" />
                    <div className="form-group row">
                      <label className="col-sm-3 form-control-label">
                        Team Logo
                      </label>
                      <div className="col-sm-9">
                        <input
                          //   id="image"
                          type="file"
                          //   name="image"
                          className="form-control"
                          onChange={(e) => {
                            setLogo(e.target.files[0]);
                          }}
                        />
                        {logo && (
                          <img
                            src={url}
                            className="img-fluid rounded-circle"
                            width="100px"
                            height="100px"
                          />
                        )}
                        {!logo && team && (
                          <img
                            src={`https://rnews-api.devserapp.com/getteam/${team?.country_code}`}
                            width={100}
                            className="img-fluid rounded-circle"
                          />
                        )}
                      </div>
                    </div>
                    <div className="line"> </div>
                    <div className="form-group row">
                      <div className="col-sm-4 offset-sm-3">
                        <Link
                          to="/home/manageteam"
                          type="button"
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditTeam;
